import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Modal, TextField } from "@mui/material";
import { collection, onSnapshot, updateDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const houseColors = {
  red: "#D32F2F",
  purple: "#6A1B9A",
  green: "#388E3C",
  yellow: "#FBC02D",
};

const Scoreboard = ({ role }) => {
  const [scores, setScores] = useState({
    red: 0,
    purple: 0,
    green: 0,
    yellow: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [newScore, setNewScore] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const isOrganizer = role === "organizer";

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "scores"), (snapshot) => {
      if (!snapshot.empty) {
        const docData = snapshot.docs[0].data();
        setScores({
          red: docData.red || 0,
          purple: docData.purple || 0,
          green: docData.green || 0,
          yellow: docData.yellow || 0,
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const handleEditClick = (house) => {
    setSelectedHouse(house);
    setNewScore(scores[house]);
    setErrorMessage("");
    setModalOpen(true);
  };

  const handleSave = async () => {
    if (selectedHouse !== null) {
      const snapshot = await getDocs(collection(db, "scores"));
      if (!snapshot.empty) {
        const scoreDoc = snapshot.docs[0].ref;
        await updateDoc(scoreDoc, { [selectedHouse]: parseInt(newScore) });
        setModalOpen(false);
      }
    }
  };

  const handleScoreChange = (e) => {
    const value = e.target.value;
    setNewScore(value);

    if (value.includes("2137")) {
      setErrorMessage("To Ci się nie uda ;)");
    } else {
      setErrorMessage("");
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        height: "100vh",
        position: "relative",
      }}
    >
      {Object.keys(houseColors).map((house) => (
        <Box
          key={house}
          sx={{
            backgroundColor: houseColors[house],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Typography variant="h3" sx={{ color: "#fff", fontWeight: "bold" }}>
            {scores[house]}
          </Typography>

          {isOrganizer && (
            <Button
              onClick={() => handleEditClick(house)}
              variant="contained"
              sx={{
                position: "absolute",
                bottom: "10px",
                backgroundColor: "black",
                color: "white",
                fontSize: "12px",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              EDYTUJ
            </Button>
          )}
        </Box>
      ))}

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            p: 3,
            bgcolor: "white",
            margin: "auto",
            width: "300px",
            textAlign: "center",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6">Edytuj punkty</Typography>
          <TextField
            type="number"
            value={newScore}
            onChange={handleScoreChange}
            fullWidth
            margin="normal"
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <Button
            variant="contained"
            onClick={handleSave}
            sx={{ mt: 2 }}
            disabled={errorMessage !== ""}
          >
            Zapisz
          </Button>
        </Box>
      </Modal>

      <Button
        onClick={() => {
          if (role === "participant" || role === "organizer") {
            navigate("/calendar");
          } else {
            navigate("/");
          }
        }}
        variant="contained"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "black",
          color: "white",
          fontSize: "16px",
          borderRadius: "12px",
          textTransform: "none",
          zIndex: 20,
        }}
      >
        Warsztaty
      </Button>
    </Box>
  );
};

export default Scoreboard;
