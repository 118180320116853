import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, MenuItem } from "@mui/material";

const days = ["Czwartek", "Piątek", "Sobota", "Niedziela"];
const categories = [
  { name: "organizatorzy", color: "#b10000" },
  { name: "poi", color: "#005C53" },
  { name: "kije", color: "#006dff" },
  { name: "hula", color: "#d5009b" },
  { name: "aerial", color: "#7E57C2" },
  { name: "wachlarze", color: "#93dbe6" },
  { name: "żonglerka", color: "#ffbf48" },
  { name: "inne kuglarskie", color: "#92b996" },
  { name: "inne niekuglarskie", color: "#928e8f" },
  { name: "W.U.R", color: "#6B21BF" },
  { name: "turniej domów", color: "#F9A881" },
];

const WorkshopModal = ({ open, handleClose, saveWorkshop, workshopToEdit }) => {
  const [workshop, setWorkshop] = useState({
    title: "",
    day: "",
    startTime: "",
    endTime: "",
    description: "",
    category: "",
    location: "",
    instructor: "",
  });

  useEffect(() => {
    if (workshopToEdit) {
      setWorkshop(workshopToEdit);
    } else {
      setWorkshop({
        title: "",
        day: "",
        startTime: "",
        endTime: "",
        description: "",
        category: "",
        location: "",
        instructor: "",
      });
    }
  }, [workshopToEdit, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkshop((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      saveWorkshop(workshop);
      handleClose();
    }
  };

  const isFormValid = () => {
    const { title, day, startTime, endTime, category, location, instructor } =
      workshop;
    if (!title || !day || !startTime) return false;
    if (endTime && startTime >= endTime) return false;
    if (category !== "organizatorzy" && !location) return false;
    if (category !== "organizatorzy" && !instructor) return false;
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 2,
          bgcolor: "background.paper",
          margin: "auto",
          width: "90%",
          maxWidth: "500px",
          maxHeight: "90vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2>Dodaj warsztat</h2>
        <TextField
          fullWidth
          margin="normal"
          label="Tytuł"
          name="title"
          value={workshop.title}
          onChange={handleChange}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Opis"
          name="description"
          value={workshop.description}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          select
          margin="normal"
          label="Dzień"
          name="day"
          value={workshop.day}
          onChange={handleChange}
          required
        >
          {days.map((day) => (
            <MenuItem key={day} value={day}>
              {day}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          margin="normal"
          label="Początek"
          name="startTime"
          type="time"
          value={workshop.startTime}
          onChange={handleChange}
          required
          inputProps={{
            step: 60,
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Koniec"
          name="endTime"
          type="time"
          value={workshop.endTime}
          onChange={handleChange}
          inputProps={{
            step: 60,
          }}
          error={workshop.endTime && workshop.startTime >= workshop.endTime}
          helperText={
            workshop.endTime && workshop.startTime >= workshop.endTime
              ? "Koniec wcześniej niż początek? 🤔"
              : ""
          }
        />
        <TextField
          fullWidth
          select
          margin="normal"
          label="Kategoria"
          name="category"
          value={workshop.category}
          onChange={handleChange}
          required
        >
          {categories.map((category) => (
            <MenuItem key={category.name} value={category.name}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          margin="normal"
          label="Miejsce"
          name="location"
          value={workshop.location}
          onChange={handleChange}
          required={workshop.category !== "organizatorzy"}
          helperText={
            workshop.category === "organizatorzy"
              ? "Pole niewymagane w przypadku kategorii 'organizatorzy'."
              : ""
          }
        />
        <TextField
          fullWidth
          margin="normal"
          label="Prowadzący"
          name="instructor"
          value={workshop.instructor}
          onChange={handleChange}
          required={workshop.category !== "organizatorzy"}
          helperText={
            workshop.category === "organizatorzy"
              ? "Pole niewymagane w przypadku kategorii 'organizatorzy'."
              : ""
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
          Zapisz
        </Button>
      </Box>
    </Modal>
  );
};

export default WorkshopModal;
