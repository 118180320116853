import React from 'react';
import { Card, CardContent, Typography, Button, CardActions } from '@mui/material';

const categories = {
    'organizatorzy': '#b10000',
    'poi': '#005C53',
    'kije': '#3357ff',
    'hula': '#d5009b',
    'aerial': '#D1C4E9',
    'wachlarze': '#93dbe6',
    'żonglerka': '#ffbf48',
    'inne kuglarskie': '#92b996',
    'inne niekuglarskie': '#928e8f',
    'W.U.R': '#6B21BF',
    'turniej domów': '#F9A881'
};

// const getTextColor = (backgroundColor) => {
//     const rgb = parseInt(backgroundColor.slice(1), 16);
//     const r = (rgb >> 16) & 0xff;
//     const g = (rgb >> 8) & 0xff;
//     const b = (rgb >> 0) & 0xff;
//     const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
//     return luma > 128 ? 'black' : 'white';
// };

const getTextColor = (backgroundColor) => {
    if (!backgroundColor || typeof backgroundColor !== 'string') {
        // console.error("backgroundColor is not a valid string:", backgroundColor);
        return 'black';
    }

    const rgb = parseInt(backgroundColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma > 128 ? 'black' : 'white';
};


const WorkshopCard = ({ workshop, onEdit, onDelete, role }) => {
    const categoryColor = categories[workshop.category];
    const textColor = getTextColor(categoryColor);

    return (
        <Card style={{ marginBottom: '10px', backgroundColor: categoryColor }}>
            <CardContent>
                <Typography variant="h6" style={{ color: textColor }}>{workshop.title}</Typography>
                <Typography variant="body2" style={{ color: textColor }}>
                    {workshop.endTime ? (
                        `${workshop.startTime} - ${workshop.endTime}`
                    ) : (
                        workshop.startTime
                    )}
                </Typography>
                <Typography variant="body2" style={{ color: textColor }}>{workshop.description}</Typography>
                <Typography variant="body2" style={{ color: textColor }}>
                    {workshop.category}
                </Typography>
                {workshop.location && (
                    <Typography variant="body2" style={{ color: textColor }}>
                        <strong>Miejsce: </strong>{workshop.location}
                    </Typography>
                )}
                {workshop.category !== 'organizatorzy' && (
                    <Typography variant="body2" style={{ color: textColor }}>
                        Prowadzący: {workshop.instructor}
                    </Typography>
                )}
            </CardContent>
            {role === 'organizer' && (
                <CardActions>
                    <Button size="small" style={{ color: textColor }} onClick={onEdit}>
                        Edytuj
                    </Button>
                    <Button size="small" style={{ color: textColor }} onClick={onDelete}>
                        Usuń
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default WorkshopCard;
